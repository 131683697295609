import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Content from "../components/content/content"

const PrivacyPolicyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/privacy-policy.md/" }) {
        html
      }
    }
  `)

  return (
    <Content title="Privacy Policy" htmlContent={data.markdownRemark.html} />
  )
}

export default PrivacyPolicyPage
