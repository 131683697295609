import React from "react"

import Layout from "../layout"
import SEO from "../seo"

const Content = ({ title, htmlContent }) => {
  return (
    <Layout title={title}>
      <SEO title={title} />
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          css={`
            max-width: 800px;
            width: 100%;
            padding: 0 30px 30px 30px;

            @media (min-width: ${props => props.theme.screen.tablet}) {
              padding: 0 60px 30px 60px;
            }
          `}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
    </Layout>
  )
}

export default Content
